import supabase from "../services/supabase";
import React, { useEffect, useState, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { AdminInterface } from "./AdminInterface";
import Modal from "react-bootstrap/Modal";
import logo from './IPTalonsLogo.png';
import "../App.css";
import TrialBanner from './TrialBanner';
import ProgressBanner from "./ProgressBanner";
import TermsOfUseModal from "./TermsOfUseModal";
import { useSearchParams } from 'react-router-dom';

const Home = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || 'Dashboard';
  const [customLinks, setCustomLinks] = useState([]);
  const [driveUrl, setDriveUrl] = useState(null);
  const [parameter, setParameter] = useState(null);
  const [variant, setVariant] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [accountType, setAccountType] = useState("paid");
  const [trialEndsAt, setTrialEndsAt] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [currentIframeUrl, setCurrentIframeUrl] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  // Track if we're using beta version for Grant Hopper
  const [usingBetaGrantHopper, setUsingBetaGrantHopper] = useState(false);

  const initializeGrantHopper = useCallback((iframe, retries = 3) => {
    const sendToken = () => {
      try {
        const tokenString = localStorage.getItem('sb-api-auth-token');
        
        if (!tokenString) return false;
        
        const tokenData = JSON.parse(tokenString);
        if (!tokenData?.access_token) {
          console.error('Invalid token data:', tokenData);
          return false;
        }

        // More specific check for sandbox URLs
        const targetOrigin = iframe.src.includes('beta.granthopper-sandbox.pages.dev')
          ? 'https://beta.granthopper-sandbox.pages.dev'
          : iframe.src.includes('granthopper-sandbox.pages.dev')
            ? 'https://granthopper-sandbox.pages.dev'
            : process.env.NODE_ENV === 'development'
              ? 'http://localhost:8788'
              : 'https://granthopper.iptalons.com';

        iframe.contentWindow.postMessage({
          type: 'AUTH_TOKEN',
          token: tokenData.access_token,
          refresh_token: tokenData.refresh_token,
          expires_at: tokenData.expires_at
        }, targetOrigin);
        
        return true;
      } catch (error) {
        console.error('Error initializing Grant Hopper:', error);
        return false;
      }
    };
  
    let attempts = 0;
    const tryInit = () => {
      if (attempts >= retries) {
        console.error('Failed to initialize Grant Hopper after', retries, 'attempts');
        return;
      }
      
      if (!sendToken()) {
        attempts++;
        setTimeout(tryInit, 1000);
      }
    };
  
    tryInit();
  }, []); // No dependencies needed for this function

  const buildDashboardUrl = useCallback((baseUrl, filters) => {
    // Only proceed with filters for Natcast users
    if (!(user.email?.endsWith('@natcast.org') || variant === "Natcast")) {
      return baseUrl;
    }

    // Extract relevant filter parameters
    const projectId = filters.get('project');
    const folderId = filters.get('folder');
    const subFolderId = filters.get('sub_folder');
    const researcherId = filters.get('researcher');
    
    // Build the filter parameters object
    let params = {};
    if (projectId) params["df20"] = `include%EE%80%800%EE%80%80IN%EE%80%80${projectId}`;
    if (folderId) params["df212"] = `include%EE%80%800%EE%80%80IN%EE%80%80${folderId}`;
    if (subFolderId) params["df213"] = `include%EE%80%800%EE%80%80IN%EE%80%80${subFolderId}`; // Assuming this is the sub_folder param
    if (researcherId) params["df272"] = `include%EE%80%800%EE%80%80IN%EE%80%80${researcherId}`;
    
    // If no filters, return base URL
    if (Object.keys(params).length === 0) return baseUrl;
    
    // Construct the URL with filters
    return `${baseUrl}?params=${encodeURIComponent(JSON.stringify(params))}`;
  }, [user.email, variant]);

  const customizeIframe = useCallback((url = null, forceRefresh = false) => {
    const iframe = document.querySelector('#portal');
    
    if (!url) {
      let reportHeight;
      const reportWidth = 1239;
      
      let baseUrl = parameter;
      if (!parameter.startsWith('https://lookerstudio.google.com/embed/reporting')) {
        baseUrl = variant === 'Redbook'
          ? `https://lookerstudio.google.com/embed/reporting/429b899e-02c2-4973-9627-b482a110e15e/page/Hpd7D?params=%7B"df189":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${parameter}"%7D`
          : 'https://lookerstudio.google.com/embed/reporting/9fe9f1e8-8158-41e8-bbe2-e309e556b8bd/page/Hpd7D';
      }
      
      const finalUrl = buildDashboardUrl(baseUrl, searchParams);
      
      // Calculate proportional height
      if (finalUrl.includes('lookerstudio.google.com/embed/reporting/bc80b348-c077-493f-94e5')) {
        reportHeight = 4000;
      } else {
        reportHeight = 5740;
      }
      const currentWidth = iframe.offsetWidth;
      const proportionalHeight = (currentWidth / reportWidth) * reportHeight;
      
      if (forceRefresh || iframe.getAttribute('src') !== finalUrl) {
        iframe.style.height = `${Math.round(proportionalHeight)}px`;
        // For force refresh, temporarily clear and reset the src
        if (forceRefresh && iframe.getAttribute('src') === finalUrl) {
          const currentSrc = iframe.getAttribute('src');
          iframe.setAttribute('src', 'about:blank');
          setTimeout(() => {
            iframe.setAttribute('src', currentSrc);
          }, 50);
        } else {
          iframe.setAttribute('src', finalUrl);
        }
        setCurrentIframeUrl(finalUrl);
      }
    } else {
      // Determine if this is the beta version based on the original URL
      const isBetaVersion = url.includes('beta.granthopper-sandbox.pages.dev');
      const isProductionVersion = url.includes('granthopper.iptalons.com');
      
      // Update the beta status for use in event listeners
      setUsingBetaGrantHopper(isBetaVersion);
      
      let finalUrl = url;
      if (process.env.NODE_ENV === 'development') {
        // In development, replace Grant Hopper URLs with localhost
        if (isBetaVersion || isProductionVersion || url.includes('granthopper-sandbox.pages.dev')) {
          finalUrl = url.replace(/(https:\/\/[^/]+)/, 'http://localhost:8788');
        }
      }

      if (iframe.getAttribute('src') !== finalUrl) {
        iframe.setAttribute('src', finalUrl);
        setCurrentIframeUrl(finalUrl);
        
        // For production version (and non-beta sandbox), retain existing onload behavior
        if (isProductionVersion || 
            (url.includes('granthopper-sandbox.pages.dev') && !isBetaVersion)) {
          iframe.onload = () => initializeGrantHopper(iframe);
        }
        // For beta version, don't set onload - we'll wait for the GRANT_HOPPER_READY message
        
        if (finalUrl.includes('lookerstudio.google.com/embed/reporting/bc80b348-c077-493f-94e5')) {
          // Special case for specific Looker dashboard
          const reportWidth = 1239;
          const reportHeight = 4000;
          const currentWidth = iframe.offsetWidth;
          const proportionalHeight = (currentWidth / reportWidth) * reportHeight;
          iframe.style.height = `${Math.round(proportionalHeight)}px`;
        } else if (url.includes('lookerstudio.google.com')) {
          const reportWidth = 1239;
          const reportHeight = 5740;
          const currentWidth = iframe.offsetWidth;
          const proportionalHeight = (currentWidth / reportWidth) * reportHeight;
          iframe.style.height = `${Math.round(proportionalHeight)}px`;
        } else {
          iframe.style.height = window.innerHeight - 110 + 'px';
        }
      }
    }
  }, [parameter, variant, searchParams, buildDashboardUrl, initializeGrantHopper]);

  const handleTabChange = useCallback((tabName, url) => {
    setSearchParams({ tab: tabName });
    customizeIframe(url);
  }, [setSearchParams, customizeIframe]);

  const fetchUserData = useCallback(async () => {
    const { data, error } = await supabase
      .from('iptalons_parameters')
      .select('*')
      .eq('email', user.email);

    if (error) {
      console.error('Error fetching data from Supabase:', error);
      return;
    }

    const userData = data[0];
    setCustomLinks(userData.custom_links || []);
    setDriveUrl(userData.drive_url || "");
    setParameter(userData.parameter || "");
    setVariant(userData.variant || "");
    setAccountType(userData.account_type || "paid");
    setTrialEndsAt(userData.trial_ends_at || null);

    // Simplified terms check
    const shouldShowTerms = (userData.email?.endsWith('@natcast.org') || userData.variant === "Natcast") && !userData.terms_accepted;
    setShowTermsModal(shouldShowTerms);

    // Set default tab for users with Grant Hopper link
    const grantHopperLink = userData.custom_links?.find(link => 
      link.name === "Grant Hopper" || link.name === "Hopper"
    );
    
    if (grantHopperLink && !searchParams.get('tab')) {
      setSearchParams({ tab: grantHopperLink.name });
    }

    setIsReady(true);
  }, [user.email, searchParams, setSearchParams]);

  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user, fetchUserData]);

  useEffect(() => {
    const handleGrantHopperMessage = (event) => {
      const allowedOrigins = [
        'https://granthopper.iptalons.com',
        'http://localhost:8788',
        'https://granthopper-sandbox.pages.dev',
        'https://beta.granthopper-sandbox.pages.dev'
      ];
      
      if (!allowedOrigins.includes(event.origin)) return;
      
      if (event.data.type === 'GRANT_HOPPER_AUTH_CHANGE') {
      } else if (event.data.type === 'GRANT_HOPPER_READY') {
        // New handshake mechanism for beta version
        // When beta version signals readiness, send the token
        const iframe = document.querySelector('#portal');
        if (iframe && usingBetaGrantHopper) {
          console.log('Received GRANT_HOPPER_READY, sending auth token');
          initializeGrantHopper(iframe);
        }
      }
    };

    window.addEventListener('message', handleGrantHopperMessage);
    return () => window.removeEventListener('message', handleGrantHopperMessage);
  }, [initializeGrantHopper, usingBetaGrantHopper]);

  useEffect(() => {
    if (!isReady) return;
    
    const tab = searchParams.get('tab') || 'Dashboard';
    if (tab === 'Dashboard') {
      customizeIframe();
    } else {
      const link = customLinks.find(l => l.name === tab);
      if (link) {
        customizeIframe(link.url);
      }
    }
  }, [isReady, searchParams, customLinks, customizeIframe]);

  // Add this function to handle terms acceptance
  const handleTermsAccept = async () => {
    try {
      const { error } = await supabase
        .from('iptalons_parameters')
        .update({ terms_accepted: true }) // Only updating this one field
        .eq('email', user.email)
        .select();
  
      if (error) {
        console.error('Update error:', error);
        return;
      }
  
      setShowTermsModal(false);
    } catch (error) {
      console.error('Error in handleTermsAccept:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      // First clear any local storage items with the correct key
      localStorage.removeItem('sb-api-auth-token');
      
      // Attempt to sign out from Supabase
      const { error } = await supabase.auth.signOut();
      
      if (error && !error.message.includes('Auth session missing')) {
        console.error('Error signing out:', error.message);
      }
      
      // Force reload the page to clear any remaining state
      window.location.href = '/';
    } catch (err) {
      console.error('Error in handleSignOut:', err);
      // Force reload anyway as a fallback
      window.location.href = '/';
    }
  };

  const handleRefresh = useCallback(async () => {
    try {
      // Check for updated parameters from Supabase
      const { data, error } = await supabase
        .from('iptalons_parameters')
        .select('*')
        .eq('email', user.email)
        .single();

      if (error) {
        console.error('Error fetching data from Supabase:', error);
        return;
      }

      // Update local state if parameters have changed
      if (data.parameter !== parameter || data.variant !== variant) {
        setParameter(data.parameter || "");
        setVariant(data.variant || "");
        // The useEffect will handle the iframe update due to parameter/variant change
      } else {
        // If no changes in parameters, just refresh the current iframe
        customizeIframe(null, true);
      }
    } catch (err) {
      console.error('Error in handleRefresh:', err);
    }
  }, [user.email, parameter, variant, customizeIframe]);

  const renderNavigationLinks = () => {
    if (!isReady || (!driveUrl && customLinks.length === 0)) {
      return (
        <div className="col" style={{ margin: "1%", display: 'flex', justifyContent: 'center' }}>
          <Card.Body style={{ paddingRight: "0px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <div className="navigation-links">
              {!isReady && <span style={{ color: '#FFFFFF' }}>Loading...</span>}
            </div>
          </Card.Body>
        </div>
      );
    }

    let navItems = [];

    // Check both email/variant AND custom links
    const isNatcastUser = user.email.toLowerCase().endsWith("@natcast.org") || variant === "Natcast";
    const grantHopperLink = customLinks.find(link => 
      link.name === "Grant Hopper" || link.name === "Hopper"
    );

    if ((isNatcastUser && grantHopperLink) || grantHopperLink) {
      navItems.push(
        <span 
          key="grant-hopper"
          className={`nav-link ${currentTab === grantHopperLink.name ? 'active' : ''}`} 
          onClick={() => handleTabChange(grantHopperLink.name, grantHopperLink.url)}
        >
          {grantHopperLink.name}
          {currentTab === grantHopperLink.name && <span className="underline"></span>}
        </span>
      );
    }

    navItems.push(
      <span 
        key="redbook"
        className={`nav-link ${currentTab === 'Dashboard' ? 'active' : ''}`} 
        onClick={() => handleTabChange('Dashboard')}
      >
        RedBook
        {currentTab === 'Dashboard' && <span className="underline"></span>}
      </span>
    );

    if (driveUrl) {
      navItems.push(
        <span 
          key="drive"
          className={`nav-link ${currentTab === 'Google Drive' ? 'active' : ''}`} 
          onClick={() => handleTabChange('Google Drive', driveUrl)}
        >
          Google Drive
          {currentTab === 'Google Drive' && <span className="underline"></span>}
        </span>
      );
    }

    customLinks.forEach((link, index) => {
      if (!((isNatcastUser || link.name === "Grant Hopper" || link.name === "Hopper") && 
            (link.name === "Grant Hopper" || link.name === "Hopper"))) {
        navItems.push(
          <span 
            key={index}
            className={`nav-link ${currentTab === link.name ? 'active' : ''}`} 
            onClick={() => handleTabChange(link.name, link.url)}
          >
            {link.name}
            {currentTab === link.name && <span className="underline"></span>}
          </span>
        );
      }
    });

    return (
      <div className="col" style={{ margin: "1%", display: 'flex', justifyContent: 'center' }}>
        <Card.Body style={{ paddingRight: "0px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <div className="navigation-links">
            {navItems}
          </div>
        </Card.Body>
      </div>
    );
  };

  return (
    <div className="container-fluid" style={{ overflowX: "hidden", marginTop: "0%", marginBottom: "0.5%", paddingLeft: "0", paddingRight: "0" }}>
      {accountType === 'trial' && (
        <TrialBanner trialEndsAt={trialEndsAt} />
      )}
      {user.email === 'admin@trial.edu' && (
        <ProgressBanner />
      )}
      <div className="row custom-row" style={{ backgroundColor: "#FFFFFF", borderColor: "#FFFFFF" }}>
        <div className="col-2" style={{ margin: "1%", marginLeft: "0.25%" }}>
          <img src={logo} width={"240px"} style={{ float: "left", marginRight: "24px" }} alt="Logo" />
        </div>
        {renderNavigationLinks()}
        <div className="col-2" style={{ margin: "1%", marginRight: "0%", paddingTop: "16px" }}>
          <Card.Body style={{ paddingRight: "0px", textAlign: "end", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Button 
              className="me-md-2 refresh-button" 
              style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#1C6941', borderColor: '#1C6941', color: 'white', padding: '2px 6px' }} 
              size="sm" 
              onClick={handleRefresh}
            >
              <i className="bi bi-arrow-repeat" style={{ fontSize: '18px', fontWeight: 'bold' }}></i>
            </Button>
            {user && user.email === "admin@iptalons.com" && (
              <Button 
                className="me-md-2" 
                style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#787A7B', borderColor: '#787A7B', color: '#FFFFFF', padding: '2px 6px', display: 'flex', alignItems: 'center' }} 
                size="sm" 
                onClick={() => setShowAdminModal(true)}
              >
                <i className="bi bi-person-gear" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '4px', verticalAlign: 'middle' }}></i>
                <span style={{ lineHeight: '18px' }}>Admin</span>
              </Button>
            )}
            <Button className="signout-button" style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#72ab32', borderColor: '#72ab32', color: '#FFFFFF', padding: '2px 6px', display: 'flex', alignItems: 'center' }} size="sm" onClick={handleSignOut}>
              <i className="bi bi-box-arrow-right" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '4px', verticalAlign: 'middle' }}></i>
              <span style={{ lineHeight: '18px' }}>Sign Out</span>
            </Button>
          </Card.Body>
        </div>
      </div>
      <div className="row">
        <div className="position-relative w-100" style={{ overflow: 'hidden' }}>
          <iframe 
            title="Dashboard Portal" 
            id="portal" 
            width="100%" 
            height="6060"
            style={{ display: 'block' }} 
          />
          {currentIframeUrl.includes('lookerstudio.google.com') && (
            <div 
              className="position-absolute bottom-0 start-0 end-0"
              style={{ 
                height: '24px',
                zIndex: 1000,
                cursor: 'default',
                background: 'linear-gradient(to right, #1c6941, #72ab32)'
              }} 
            />
          )}
        </div>
      </div>

      {/* Admin Modal */}
      <Modal show={showAdminModal} onHide={() => setShowAdminModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Admin Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminInterface />
        </Modal.Body>
      </Modal>
      {/* {(user.email === "users who signed up for T2BI") && (
        <>
          <Modal show={showChat} onHide={() => setShowChat(false)}>
            <Modal.Dialog style={{ position: 'fixed', bottom: '0px', right: '20px', maxWidth: '800px', borderRadius: '5px' }}>
              <Modal.Header closeButton>
                <Button onClick={() => window.open(`https://chat-widget.text2bi.com/?token=${idToken}`, '_blank')} style={{ marginRight: 'auto', backgroundColor: '#f8f9fa', borderColor: '#f8f9fa', color: '#343a40' }}>
                  <i className="bi bi-box-arrow-up-right"></i>
                </Button>
              </Modal.Header>
              <Modal.Body style={{ height: '600px', width: '450px', padding: 0, borderRadius: '5px' }}>
                <iframe title="Chat Portal" style={{ width: "100%", height: "100%" }} src={`https://chat-widget.text2bi.com/?token=${idToken}`}></iframe>
              </Modal.Body>
            </Modal.Dialog>
          </Modal>
          <Button onClick={() => setShowChat(true)} style={{ backgroundColor: '#3BA37F', borderColor: '#3BA37F', color: 'white', borderRadius: '50%', width: '60px', height: '60px', position: 'fixed', bottom: '20px', right: '20px', fontSize: '24px' }}>
            <i className="bi bi-chat-dots"></i>
          </Button>
        </>
      )} */}
      <div className="container" style={{ marginTop: "1.5%" }}>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-6 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <span className="mb-3 mb-md-0 text-muted">&copy; 2025 IPTalons, Inc. All rights reserved.</span>
          </div>
        </footer>
      </div>
      <TermsOfUseModal 
        show={showTermsModal} 
        onAccept={handleTermsAccept}
      />
    </div>
  );
};

export default Home;