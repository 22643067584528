import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Spinner, Alert, Card, Row, Col } from 'react-bootstrap';

const LOGO_SERVICE_URL = 'https://university-logo-service.vindiw.workers.dev';

// Main component definition
const UniversityLogoManager = ({ selectedUniversity }) => {
  const [logoExists, setLogoExists] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [inputUrl, setInputUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Extract OpenAlex ID function wrapped in useCallback
  const getOpenAlexId = useCallback(() => {
    if (!selectedUniversity) return null;
    const match = selectedUniversity.id.match(/I\d+/);
    return match ? match[0] : null;
  }, [selectedUniversity]);

  // Check if logo exists when university selection changes
  useEffect(() => {
    const checkLogo = async () => {
      if (!selectedUniversity) {
        setLogoExists(false);
        setLogoUrl('');
        setPreviewUrl('');
        setError('');
        setSuccess('');
        return;
      }
      
      const openAlexId = getOpenAlexId();
      if (!openAlexId) return;
      
      setIsLoading(true);
      setError('');
      
      try {
        const response = await fetch(`${LOGO_SERVICE_URL}/check?id=${openAlexId}`);
        const data = await response.json();
        
        if (data.success) {
          setLogoExists(data.exists);
          setLogoUrl(data.exists ? data.url : '');
        } else {
          setError('Error checking logo status');
        }
      } catch (err) {
        setError('Error connecting to logo service');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkLogo();
  }, [selectedUniversity, getOpenAlexId]);

  // Preview logo
  const handlePreview = async () => {
    if (!inputUrl) {
      setError('Please enter a logo URL');
      return;
    }
    
    setPreviewLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const response = await fetch(`${LOGO_SERVICE_URL}/preview`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl: inputUrl })
      });
      
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPreviewUrl(url);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Error previewing logo');
      }
    } catch (err) {
      setError('Error connecting to logo service');
      console.error(err);
    } finally {
      setPreviewLoading(false);
    }
  };

  // Save logo
  const handleSave = async () => {
    const openAlexId = getOpenAlexId();
    if (!openAlexId) {
      setError('Missing OpenAlex ID');
      return;
    }
    
    if (!inputUrl) {
      setError('Please enter a logo URL');
      return;
    }
    
    setIsLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const response = await fetch(`${LOGO_SERVICE_URL}/process`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          imageUrl: inputUrl,
          openAlexId,
          universityName: selectedUniversity.display_name
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        setLogoExists(true);
        setLogoUrl(data.url);
        setSuccess('Logo saved successfully!');
        setInputUrl('');
        setPreviewUrl('');
      } else {
        setError(data.error || 'Error saving logo');
      }
    } catch (err) {
      setError('Error connecting to logo service');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!selectedUniversity) {
    return null;
  }

  // Get university website
  const getUniversityWebsite = () => {
    if (!selectedUniversity) return '';
    return selectedUniversity.homepage_url || 
           `https://www.google.com/search?q=${encodeURIComponent(selectedUniversity.display_name + ' website')}`;
  };

  const universityWebsite = getUniversityWebsite();

  return (
    <Card className="mt-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0">University Logo</h6>
        {logoExists && (
          <Button 
            variant="link" 
            size="sm"
            className="p-0 text-decoration-none"
            onClick={() => {
              setLogoExists(false);
              setSuccess('');
            }}
          >
            Replace Logo
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div className="text-center py-3">
            <Spinner animation="border" size="sm" className="me-2" />
            Loading...
          </div>
        ) : logoExists ? (
          <div>
            <div className="text-center mb-3">
              <div 
                className="d-inline-block p-2 border" 
                style={{
                  width: '640px',
                  maxWidth: '100%',
                  height: '154px',
                  background: '#f8f9fa',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img 
                  src={logoUrl} 
                  alt={`${selectedUniversity.display_name} logo`}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            </div>
            <Alert variant="success">
              <Alert.Heading>Logo is set</Alert.Heading>
              <p className="mb-0">
                This logo will appear in the dashboard header for this university. 
                The logo is sized appropriately (640×154px) and stored with this university's OpenAlex ID.
              </p>
            </Alert>
            <div className="d-flex gap-2">
              <Button 
                variant="outline-primary" 
                size="sm"
                onClick={() => window.open(logoUrl, '_blank')}
              >
                <i className="bi bi-box-arrow-up-right me-1"></i>
                Open Logo in New Tab
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Alert variant="info">
              <Alert.Heading>No Logo Set</Alert.Heading>
              <p>Please add a logo for the university to display in the dashboard header.</p>
              
              <hr />
              
              <p className="mb-1"><strong>Tips for finding a good logo:</strong></p>
              <ol className="mb-0">
                <li>Visit the <a href={universityWebsite} target="_blank" rel="noreferrer">university website</a></li>
                <li>Right-click on their logo in the header and select "Open image in new tab"</li>
                <li>Copy the URL from the address bar of the new tab</li>
                <li><strong>Preferred formats:</strong> PNG with transparent background</li>
                <li><strong>Best layout:</strong> Horizontal logos work best with our dashboard</li>
                <li>The logo will be resized to fit properly in a 640×154px area</li>
              </ol>
            </Alert>
            
            <Form.Group className="mb-3">
              <Form.Label>Logo URL</Form.Label>
              <Row>
                <Col md={9}>
                  <Form.Control
                    type="url"
                    placeholder="https://university.edu/images/logo.png"
                    value={inputUrl}
                    onChange={(e) => setInputUrl(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <Button
                    variant="outline-secondary"
                    onClick={handlePreview}
                    disabled={!inputUrl || previewLoading}
                    className="w-100"
                  >
                    {previewLoading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" className="me-1" />
                        Previewing...
                      </>
                    ) : 'Preview Logo'}
                  </Button>
                </Col>
              </Row>
              <Form.Text className="text-muted">
                Enter the direct URL to the university's logo image
              </Form.Text>
            </Form.Group>
            
            {previewUrl && (
              <div className="mt-4 mb-3">
                <h6>Preview:</h6>
                <div 
                  className="p-2 border rounded" 
                  style={{
                    width: '640px',
                    maxWidth: '100%',
                    height: '154px',
                    background: '#f8f9fa',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img 
                    src={previewUrl} 
                    alt="Logo preview"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              </div>
            )}
            
            {error && <Alert variant="danger" className="mb-3">{error}</Alert>}
            {success && <Alert variant="success" className="mb-3">{success}</Alert>}
            
            {previewUrl && (
              <Button
                variant="primary"
                onClick={handleSave}
                disabled={!inputUrl || isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" className="me-1" />
                    Saving...
                  </>
                ) : 'Save Logo'}
              </Button>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default UniversityLogoManager;