import React, { useState } from 'react';
import { X } from 'lucide-react';
import Alert from 'react-bootstrap/Alert';

const ProgressBanner = ({ 
  processed = 92,
  total = 161,
  isLoading = false
}) => {
  const [dismissed, setDismissed] = useState(false);
  
  if (dismissed) return null;
  
  const percentage = Math.round((processed / total) * 100);
  
  return (
    <Alert variant="dark" className="mb-0 py-2" style={{ 
      fontSize: '0.85rem', 
      borderRadius: 0,
      backgroundColor: '#000000',
      color: '#ffffff',
      position: 'relative',
      border: 0
    }}>
      <div className="d-flex align-items-center">
        {/* <div style={{ marginRight: '24px', whiteSpace: 'nowrap' }}>
          Processing researcher data for your university
        </div> */}
        <div style={{ marginRight: '24px', whiteSpace: 'nowrap' }}>
          Successfully processed researcher data
        </div>
        <div className="d-flex align-items-center flex-grow-1" style={{ marginRight: '120px' }}>
          <div className="progress flex-grow-1" style={{ height: '8px', backgroundColor: '#333' }}>
            <div 
              className="progress-bar"
              style={{ 
                width: `${percentage}%`,
                background: 'linear-gradient(to right, #1c6941, #72ab32)',
                transition: 'width 500ms'
              }}
            />
          </div>
        </div>
        <div style={{ position: 'absolute', right: '40px', whiteSpace: 'nowrap', color: '#72ab32' }}>
          {processed.toLocaleString()} / {total.toLocaleString()}
        </div>
      </div>
      <div style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)' }}>
        <X
          size={14}
          color="#ffffff"
          onClick={() => setDismissed(true)}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </Alert>
  );
};

export default ProgressBanner;